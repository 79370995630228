.profile-img {
  height: 300px;
  width: 300px;
  border-radius: 150px;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.profile-vals {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.edit-button {
  border: 0px;
  background: var(--col_background);
  color: var(--text_color);
}

.profile-titles {
  text-align: center;
}
