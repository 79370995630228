.song-card {
  background-color: var(--body_background);
  border-radius: 0.5rem;
  padding: 0.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text_color);
}

.song-card:hover {
  background-color: var(--hover_button);
  cursor: pointer;
}

.delete-chord {
  background-color: var(--warning);
  height: 2rem;
  font-weight: normal;
}

.delete-chord:hover {
  background-color: #ff3535;
}

.song-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.song-options-delete {
  color: rgb(133, 0, 0);
  cursor: pointer;
  margin-left: 20;
}

.delete-song {
  background-color: var(--warning);
  height: 2rem;
  font-weight: normal;
  margin-left: auto;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  color: #383838;
}

.delete-song:hover {
  background-color: #ff3535;
}

.song-options-edit {
  color: rgb(78, 120, 255);
  cursor: pointer;
  margin-right: 1rem;
  margin-left: 1rem;
}

.edit-chords {
  background-color: var(--body_background);
}

.edit-chords:hover {
  background-color: var(--hover_button);
}

.song-options-save {
  color: rgb(0, 211, 11);
  cursor: pointer;
  margin-right: 1rem;
  margin-left: 1rem;
}

.song-options-cancel {
  color: rgb(255, 89, 89);
  cursor: pointer;
  margin-right: 1rem;
  margin-left: 1rem;
}

.song-flex-child {
  flex: 1;
  margin: 1rem;
}

.name-save {
  background-color: rgb(0, 116, 6);
  color: var(--body_background);
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
}

.song-name-input {
  font-weight: 300;
  color: #383838;
  font-size: 35px;
  font-family: "Trebuchet MS";
  margin-left: 15px;
  padding: 0px;
  background: #ffb181;
}

.new-chord-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: var(--body_background);
}

.new-chord-btn:hover {
  background-color: var(--hover_button);
  cursor: pointer;
}

.new-label {
  text-align: right;
  color: rgb(0, 211, 11);
  font-weight: bold;
}

.new-label {
  animation: shimmer 2s infinite linear;
}

.song-input {
  width: 50%;
}
