body {
  background-color: var(--body_background) !important;
}

.columns {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  background: var(--col_background);
}

.section-titles {
  background: #ff914e;
  color: var(--text_color);
}

.section-titles-text {
  font-weight: 600;
  color: #383838;
  font-size: 35px;
  margin-left: 15px;
}

.section-img {
  height: 350px;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.chordless-btn {
  height: 3rem;
  background-color: #ff914e;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  color: black;
  border-width: 0px;
  font-weight: bold;
}

.chordless-btn:hover {
  background-color: #ff7e2e;
}

.sound {
  height: 2.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.center-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: var(--text_color);
}

a:link {
  text-decoration: none;
}

.buttons {
  display: inline-block;
  width: calc(50%);
  margin: 0 auto;
  padding-top: 1.5rem;
}

.selected {
  background-color: #ff914e;
}

.inner-div {
  padding: 20px;
  font-size: 20px;
  color: var(--text_color);
}

.flex-container {
  display: flex;
  align-items: center;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff914e;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 34px;
  background-color: var(--switch_background);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  bottom: 0;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 46px;
  left: 4px;
  bottom: 4px;
  background-color: var(--switch_slider);
}

input:checked + .slider:before {
  transform: translateX(46px);
}

.toast {
  background-color: var(--col_background);
}

.settings-radio {
  width: auto;
}

.radio-inline {
  display: inline-block;
  padding-left: 1.5rem;
}

.preference-item {
  border-color: var(--body_background);
  border-style: solid;
  border-radius: 10px;
  border-width: 5px;
  padding: 1.5rem;
}

.preference-item-title {
  font-weight: 500;
}
