.guitar-body-container {
  margin: 0 0 32px;
  display: flex;
  justify-content: flex-end;
}
.guitar-body {
  width: 250px;
  height: 160px;
  padding: 24px 0;
  position: relative;
}

.body-strings {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.body-string {
  flex-grow: 1;
  position: relative;
  z-index: 10;
  background-color: black;
}
.fretboard-container {
  display: flex;
  overflow: hidden;
  position: relative;
}
.fretboard-container svg {
  display: block;
}
.fretboard-bg {
  background: var(--fretboard_bg);
}

.mute-btns {
  display: flex;
  flex-direction: column-reverse;
}
.mute-btn input {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
}
.mute-btn label {
  width: 16px;
  height: 18px;
  display: block;
  background: rgba(204, 187, 170, 0.5);
  border: 1px solid #fff;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
}
.mute-btn input:checked + label::before,
.mute-btn input:checked + label::after {
  content: "";
  width: 2px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 6px;
  background: #a00;
}
.mute-btn input:checked + label::before {
  transform: rotate(45deg);
}
.mute-btn input:checked + label::after {
  transform: rotate(-45deg);
}
.fret-markers {
  position: absolute;
  top: 0;
  left: 16px;
}

.fret-markers circle {
  pointer-events: none;
}

.read-only {
  cursor: default !important;
}

.mini {
  height: 14px !important;
}

.chords {
  background: var(--body_background);
  padding: 1rem;
  border-radius: 0.5rem;
  color: var(--text_color);
}

.chord-name {
  font-weight: bold;
  font-size: 2rem;
}

.chord-name-small {
  font-weight: bold;
  font-size: 1.5rem;
}
