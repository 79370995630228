.nav-link-text {
  font-size: 1.25rem;
  font-weight: 600;
  transition: 0.1s;
}

.nav-link-text:hover {
  transform: scale(1.1);
}

.sign-in {
  margin-right: 30px;
}
