.img-404 {
  width: 300px;
  height: 250px;
}

.code-404 {
  font-size: 100px;
  color: #ff914e;
}

.error-404 {
  font-size: 50px;
  color: #ff914e;
}

.msg-404-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-shadow: 2px 2px 2px gray;
}
