:root {
  --body_background: #e6e6e6;
  --col_background: white;
  --switch_background: white;
  --switch_slider: #e6e6e6;
  --text_color: #212529;
  --warning: #ff5757;
  --hover_button: #b6b6b6;
  --fretboard_bg: #402f1f;
  --fretboard_strings: #fff;
  --fretboard_circle_selected: rgba(105, 157, 255, 0.8);
  --fretboard_circle_default: #000;
  --fretboard_circle_stroke: #fff;
}

[data-theme="dark"] {
  --body_background: #222222;
  --col_background: rgb(20, 20, 20);
  --switch_background: rgb(20, 20, 20);
  --switch_slider: #222222;
  --text_color: #e0e0e0;
  --warning: #a11e1e;
  --hover_button: #303030;
}

[data-theme="madness"] {
  --body_background: #6900af;
  --col_background: rgb(36, 0, 199);
  --switch_background: rgb(190, 0, 89);
  --switch_slider: #00c2db;
  --text_color: #00ff0d;
  --warning: #38a722;
  --hover_button: #ff00ea;
}

[data-appearance="Light"] {
  --fretboard_bg: #d8c9ba;
  --fretboard_strings: #000000;
  --fretboard_circle_selected: rgba(255, 120, 120, 0.8);
  --fretboard_circle_default: #fff;
  --fretboard_circle_stroke: #000000;
}

/* [data-appearance="Fancy"] {
  --body_background: #222222;
  --col_background: rgb(20, 20, 20);
  --switch_background: rgb(20, 20, 20);
  --switch_slider: #222222;
  --text_color: #e0e0e0;
  --warning: #a11e1e;
  --hover_button: #303030;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
