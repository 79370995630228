.center {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
  color: var(--text_color);
}
.cover {
  background-color: var(--col_background);
  width: 40%;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 1.5rem rgb(104, 104, 104);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small-window {
  width: 80% !important;
}

.alt-login {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 1rem;
}

.alt-login div {
  border-radius: 0.5rem;
  cursor: pointer;
}

input {
  border: none;
  background-color: var(--body_background);
  width: 70%;
  border-radius: 0.5rem;
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: var(--text_color);
}

input:focus {
  outline-color: #ff914e;
}

.login-btn {
  width: 40%;
  height: 4rem;
  background-color: #ff914e;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  border-radius: 0.5rem;
  cursor: pointer;
  color: white;
  text-shadow: 2px 4px 4px rgba(39, 39, 39, 0.5);
  border-width: 0px;
}

.login-btn:hover {
  transform: scale(1.05);
}

.google {
  background-color: var(--body_background);
  transform: scale(1);
  cursor: not-allowed;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 10px;
}

.input-container {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  width: 100%;
}

.alt-login-text {
  padding-top: 20px;
}

.login-text {
  padding: 10px;
}

.bottom {
  padding-bottom: 20px;
}

.google-login-text {
  color: white;
  text-shadow: 2px 4px 4px rgba(39, 39, 39, 0.5);
}

.login-status {
  background: var(--warning);
  color: var(--text_color);
  margin-bottom: 20px;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  font-size: 1.25rem;
}

.success {
  background: #4acf69;
  color: var(--text_color);
}
